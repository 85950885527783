import { FormEvent, useContext, useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'ra-core'
import Form from '@rjsf/material-ui'
import { JSONSchema7Object } from 'json-schema'
import { TreeItem } from 'react-sortable-tree'
import { IChangeEvent } from '@rjsf/core'
import { RJSFSchema, RJSFValidationError } from '@rjsf/utils/dist'
import validator from '@rjsf/validator-ajv8'

// MUI
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, Grid, Typography } from '@material-ui/core'
import ReplayIcon from '@mui/icons-material/Replay'

// GraphQL
import { useNodeQuery } from 'apollo/configurator/queries/Node.generated'
import { useUpdatePresetNodeMutation } from 'apollo/configurator/mutations/UpdatePresetNode.generated'
import { useUiSchemaQuery } from 'apollo/configurator/queries/UiSchema.generated'
import { useNodeOverrideSchemaQuery } from 'apollo/configurator/queries/NodeOverrideSchema.generated'
import { useCreatePresetNodeMutation } from 'apollo/configurator/mutations/CreatePresetNode.generated'

import { PresetModelContext } from 'context/preset-model/PresetModelContext'
import { ErrorContext } from 'context/error/ErrorContext'
import { addPresetNodeDataToTree } from 'components/presetManager/helpers/TreeHelperFunctions'
import { IPresetNode } from 'ts/interfaces'
import IconFieldTemplate from './IconFieldTemplate'
import { filterNonEmptyObjects } from './utils/filterNonEmptyObjects'
import { useDeletePresetNodeMutation } from 'apollo/configurator/mutations/DeletePresetNode.generated'

interface AttributesEditorProps {
    nodeId: number
    parentId?: string | undefined
    presetId: number
    modelCode: string
    reRenderCallback: () => void
}

const AttributesEditor = ({
    nodeId,
    presetId,
    modelCode,
    reRenderCallback
}: AttributesEditorProps) => {
    const DEFAULT_TYPE = 'root'
    const [nodeOverrideForm, setNodeOverrideForm] = useState<any>({})

    // JSON FORM Query's
    const { loading: rootNodeLoading } = useNodeQuery({
        variables: { id: nodeId.toString() },
        skip: !nodeId,
        onCompleted: (data) => {
            setNodeOverrideForm(
                data.configuratorNode && JSON.parse(data.configuratorNode?.node_attributes ?? '')
            )
        }
    })
    const { data: { configuratorUiSchema: uiSchema } = {}, loading: uiSchemaLoading } =
        useUiSchemaQuery()
    const {
        data: { configuratorNodeOverrideSchema: overrideSchema } = {},
        loading: overrideSchemaLoading
    } = useNodeOverrideSchemaQuery({
        variables: {
            model: modelCode,
            nodeType: nodeOverrideForm?.type ?? DEFAULT_TYPE
        }
    })

    // Local state
    const translate = useTranslate()
    const notify = useNotify()
    const { setErrorState } = useContext(ErrorContext)
    const { state: presetModelState, setState: setPresetModelState } =
        useContext(PresetModelContext)
    const [createPresetNodeQuery] = useCreatePresetNodeMutation()
    const [updatePresetNodeQuery] = useUpdatePresetNodeMutation()
    const [deletePresetNodeQuery] = useDeletePresetNodeMutation()
    const [uniqueKey, setUniqueKey] = useState<number>(0)
    const [formData, setFormData] = useState<unknown>(
        presetModelState?.selectedNode?.presetNode?.definition
    )
    const [treeState, setStreeState] = useState<TreeItem[]>(presetModelState.treeState)
    const [selectedNode, setSelectedNode] = useState<any>(presetModelState.selectedNode)
    const [isPristine, setIsPristine] = useState<boolean>(true)

    // Selected node changed. This changes formData which triggers a form re-render
    useEffect(() => {
        const newFormData = !!selectedNode?.presetNode?.definition
            ? JSON.parse(selectedNode.presetNode.definition)
            : nodeOverrideForm
        setFormData(newFormData)
        setUniqueKey(Date.now())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, selectedNode?.presetNode])

    useEffect(() => {
        if (presetModelState.treeState !== treeState) setStreeState(presetModelState.treeState)
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.treeState])

    useEffect(() => {
        if (presetModelState.selectedNode !== selectedNode)
            setSelectedNode(presetModelState.selectedNode)
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.selectedNode])

    useEffect(() => {
        if (!!presetModelState.reRenderForm) {
            setPresetModelState({
                ...presetModelState,
                reRenderForm: false
            })
            reRenderCallback()
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.reRenderForm])

    // JSON schema form overrides
    const overrideSchemaObject = overrideSchema && JSON.parse(overrideSchema)
    let uiSchemaObject = uiSchema && JSON.parse(uiSchema)
    uiSchemaObject = {
        ...uiSchemaObject,
        overrides: {
            items: {
                icon: { 'ui:field': IconFieldTemplate },
                description: { 'ui:widget': 'textarea' },
                'config.value_group': { 'ui:disabled': true },
                'config.validation': { 'ui:disabled': true },

                // Defaults
                version: { 'ui:readonly': true },
                constraints: { 'ui:readonly': true },
                upsell: { 'ui:readonly': true },
                valueGroups: { 'ui:readonly': true }
            }
        }
    }

    // Create presetNode handler
    const createPreset = async (formdata: string) => {
        const variables = {
            definition: formdata,
            parentNodeId: nodeId,
            presetId: presetId
        }

        await createPresetNodeQuery({ variables: variables })
            .then((result) => {
                const newTree = addPresetNodeDataToTree(
                    treeState,
                    selectedNode,
                    result?.data?.configuratorCreatePresetNode ?? ({} as IPresetNode)
                )
                setPresetModelState({
                    ...presetModelState,
                    treeState: newTree.treeData,
                    reRenderTree: true
                })
            })
            .finally(() => {
                notify('manager.resources.preset.added_successfully', 'success')
            })
    }

    // Update presetNode handler
    const updatePreset = (definition: any) => {
        const id = selectedNode?.presetNode?.id
        if (id) {
            updatePresetNodeQuery({
                variables: {
                    definition: definition,
                    configuratorUpdatePresetNodeId: id
                }
            }).then(() => {
                notify('manager.resources.preset.edited_successfully', 'success')
            })
        }
    }

    // Delete presetNode handler
    const deletePreset = () => {
        const id = selectedNode?.presetNode?.id

        if (id) {
            deletePresetNodeQuery({
                variables: {
                    id
                }
            }).then(() => {
                notify('manager.resources.preset.deleted_successfully', 'success')
            })
        }
    }

    // Form handlers
    const onErrorHandler = (errors: JSONSchema7Object[]) => {
        setErrorState({ hasError: true, message: errors[0].stack as string })
    }
    const onChangeHandler = (e: IChangeEvent<unknown>) => {
        setIsPristine(false)
        if (e.formData !== formData) setFormData(e.formData)
    }
    const onSaveHandler = (
        e: IChangeEvent<any, RJSFSchema, any>,
        nativeEvent: FormEvent<HTMLFormElement>
    ) => {
        setIsPristine(true)
        nativeEvent.stopPropagation()
        const formData = e?.formData

        if (formData && formData?.overrides) {
            const overrides = filterNonEmptyObjects(formData.overrides)

            if (!selectedNode?.presetNode) {
                createPreset(JSON.stringify({ ...formData, overrides: overrides }))
            } else {
                if (overrides) {
                    updatePreset(JSON.stringify({ ...formData, overrides: overrides }))
                } else {
                    deletePreset()
                }
            }
        } else {
            notify('manager.resources.preset.empty_submit', 'error')
        }
    }

    const TransformErrors = (errors: RJSFValidationError[]) => {
        return errors?.map((error: RJSFValidationError) => {
            console.warn(error)
            return error
        })
    }

    // Loading
    if (uiSchemaLoading || overrideSchemaLoading || rootNodeLoading) return <CircularProgress />
    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid item md={9}>
                    <Typography variant="h6">{`${translate(
                        'manager.resources.preset.manager.selected_node'
                    )}: ${nodeOverrideForm?.label ?? nodeOverrideForm?.code}`}</Typography>
                </Grid>
                <Grid item md={3}>
                    <Button onClick={reRenderCallback} endIcon={<ReplayIcon />}>
                        {translate('manager.resources.preset.manager.sync')}
                    </Button>
                </Grid>
                <Grid item md={12}>
                    {overrideSchemaObject && nodeOverrideForm && uiSchemaObject ? (
                        <Form
                            key={uniqueKey}
                            showErrorList={false}
                            schema={overrideSchemaObject}
                            uiSchema={uiSchemaObject}
                            formData={formData}
                            onSubmit={(e, nativeEvent) => onSaveHandler(e, nativeEvent)}
                            onChange={(e) => onChangeHandler(e)}
                            onError={(e) => onErrorHandler(e)}
                            transformErrors={TransformErrors}
                            validator={validator}
                        >
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disableElevation
                                disabled={isPristine}
                            >
                                Submit
                            </Button>
                        </Form>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            </Grid>
        </>
    )
}
export default AttributesEditor
