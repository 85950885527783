export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  configuratorDate: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  configuratorDateTime: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  configuratorUpload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  packagesDate: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  packagesDateTime: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  personalizationDate: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  personalizationDateTime: any;
  /** A date String with format `Y-m-d`, e.g. `2011-05-23`. */
  schoolsDate: any;
  /** A datetime String with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  schoolsDateTime: any;
  /** Arbitrary data encoded in JavaScript Object Notation. See https://www.json.org. */
  schoolsJSON: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  schoolsMixed: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  schoolsUpload: any;
};

export type ErrorArrayEntry = {
  __typename?: 'ErrorArrayEntry';
  extensions?: Maybe<Extensions>;
  locations?: Maybe<Array<Maybe<Locations>>>;
  message?: Maybe<Scalars['String']>;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  trace?: Maybe<Array<Maybe<StackTrace>>>;
};

export type Extensions = {
  __typename?: 'Extensions';
  original_message?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  relativePath?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IndexResult = {
  __typename?: 'IndexResult';
  created_at: Scalars['String'];
  school_id: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['String'];
};

export type Locations = {
  __typename?: 'Locations';
  column?: Maybe<Scalars['Int']>;
  line?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  configuratorBatchExportModelPresets: Array<Maybe<ConfiguratorPresetExportedItem>>;
  configuratorConfigurationUpdateSelection: Scalars['Boolean'];
  configuratorCreateNode?: Maybe<ConfiguratorNode>;
  configuratorCreatePreset?: Maybe<ConfiguratorPreset>;
  configuratorCreatePresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorDeleteConfiguration?: Maybe<Scalars['Boolean']>;
  configuratorDeleteNode?: Maybe<ConfiguratorNode>;
  configuratorDeletePreset?: Maybe<ConfiguratorPreset>;
  configuratorDeletePresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorDuplicateModel?: Maybe<Scalars['Int']>;
  configuratorDuplicatePreset?: Maybe<ConfiguratorPreset>;
  configuratorExportModel?: Maybe<ConfiguratorModel>;
  configuratorForgotPassword: ConfiguratorForgotPasswordResponse;
  configuratorImportJSONModel?: Maybe<ConfiguratorJsonImport>;
  configuratorLogin: ConfiguratorAuthPayload;
  configuratorLogout: ConfiguratorLogoutResponse;
  configuratorMakeDir?: Maybe<ConfiguratorMakeDirResult>;
  configuratorMoveNode?: Maybe<ConfiguratorNode>;
  configuratorRefreshToken: ConfiguratorAuthPayload;
  configuratorRegister: ConfiguratorAuthPayload;
  configuratorRemoveDir?: Maybe<Scalars['Boolean']>;
  configuratorRemoveFile?: Maybe<Scalars['Boolean']>;
  configuratorRemoveMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorSaveConfiguration?: Maybe<ConfiguratorConfigurationResult>;
  configuratorSaveMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorUpdateForgottenPassword: ConfiguratorForgotPasswordResponse;
  configuratorUpdateMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorUpdateNode?: Maybe<ConfiguratorNode>;
  configuratorUpdatePreset?: Maybe<ConfiguratorPreset>;
  configuratorUpdatePresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorUploadConfigurationMedia?: Maybe<Scalars['String']>;
  configuratorUploadModelMedia?: Maybe<ConfiguratorUploadModelMediaResult>;
  indexSchool?: Maybe<IndexResult>;
  packagesCreatePackage?: Maybe<PackagesPackage>;
  packagesCreatePackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesCreatePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesCreatePackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesCreatePackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesCreatePackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesCreatePackageGroupPackage?: Maybe<PackagesPackageGroupPackage>;
  packagesCreatePackageGroupPreset?: Maybe<PackagesPackageGroupPreset>;
  packagesCreatePackageProduct?: Maybe<PackagesPackageProduct>;
  packagesDeletePackage?: Maybe<PackagesPackage>;
  packagesDeletePackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesDeletePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesDeletePackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesDeletePackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesDeletePackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesDeletePackageGroupPackage?: Maybe<Scalars['Boolean']>;
  packagesDeletePackageGroupPreset?: Maybe<PackagesPackageGroupPreset>;
  packagesDeletePackageProduct?: Maybe<PackagesPackageProduct>;
  packagesDuplicatePackage?: Maybe<PackagesPackage>;
  packagesDuplicatePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesSavePackageSelection?: Maybe<PackagesSavedPackage>;
  packagesUpdatePackage?: Maybe<PackagesPackage>;
  packagesUpdatePackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesUpdatePackageGroup?: Maybe<PackagesPackageGroup>;
  packagesUpdatePackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesUpdatePackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesUpdatePackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesUpdatePackageGroupPackage?: Maybe<PackagesPackageGroupPreset>;
  packagesUpdatePackageGroupPreset?: Maybe<PackagesPackageGroupPreset>;
  packagesUpdatePackageProduct?: Maybe<PackagesPackageProduct>;
  personalizationCreateRule: PersonalizationRule;
  personalizationDeleteRule?: Maybe<PersonalizationRule>;
  personalizationDuplicateRule: PersonalizationRule;
  personalizationFlushActionsCache?: Maybe<Scalars['Boolean']>;
  personalizationFlushProductsCache?: Maybe<Scalars['Boolean']>;
  personalizationUpdateRule: PersonalizationRule;
  personalizationUpdateRulesPaths?: Maybe<Scalars['Boolean']>;
  schoolsCreateEducation?: Maybe<SchoolsEducation>;
  schoolsCreateExtensionAttribute?: Maybe<SchoolsExtensionAttribute>;
  schoolsCreateSchool?: Maybe<SchoolsSchool>;
  schoolsCreateSchoolClass?: Maybe<SchoolsSchoolClass>;
  schoolsCreateSchoolClassRepresentative?: Maybe<SchoolsSchoolClassRepresentative>;
  schoolsCreateSchoolClassSchoolClassRepresentative?: Maybe<SchoolsSchoolClassSchoolClassRepresentative>;
  schoolsCreateSchoolClassSchoolTag?: Maybe<SchoolsSchoolClassSchoolTag>;
  schoolsCreateSchoolEducation?: Maybe<SchoolsSchoolEducation>;
  schoolsCreateSchoolPeriod?: Maybe<SchoolsSchoolPeriod>;
  schoolsCreateSchoolSchoolClassRepresentative?: Maybe<SchoolsSchoolSchoolClassRepresentative>;
  schoolsCreateSchoolSchoolTag?: Maybe<SchoolsSchoolSchoolTag>;
  schoolsCreateSchoolTag?: Maybe<SchoolsSchoolTag>;
  schoolsCreateSchoolYear?: Maybe<SchoolsSchoolYear>;
  schoolsDeleteEducation?: Maybe<SchoolsEducation>;
  schoolsDeleteExtensionAttribute?: Maybe<SchoolsExtensionAttribute>;
  schoolsDeleteSchool?: Maybe<SchoolsSchool>;
  schoolsDeleteSchoolClass?: Maybe<SchoolsSchoolClass>;
  schoolsDeleteSchoolClassRepresentative?: Maybe<SchoolsSchoolClassRepresentative>;
  schoolsDeleteSchoolClassSchoolClassRepresentative?: Maybe<SchoolsSchoolClassSchoolClassRepresentative>;
  schoolsDeleteSchoolClassSchoolTag?: Maybe<SchoolsSchoolClassSchoolTag>;
  schoolsDeleteSchoolEducation?: Maybe<SchoolsSchoolEducation>;
  schoolsDeleteSchoolPeriod?: Maybe<SchoolsSchoolPeriod>;
  schoolsDeleteSchoolSchoolClassRepresentative?: Maybe<SchoolsSchoolSchoolClassRepresentative>;
  schoolsDeleteSchoolSchoolTag?: Maybe<SchoolsSchoolSchoolTag>;
  schoolsDeleteSchoolTag?: Maybe<SchoolsSchoolTag>;
  schoolsDeleteSchoolYear?: Maybe<SchoolsSchoolYear>;
  schoolsImportSchoolClassRepresentatives?: Maybe<Scalars['Boolean']>;
  schoolsImportSchoolClasses?: Maybe<Scalars['Boolean']>;
  schoolsImportSchools?: Maybe<Scalars['Boolean']>;
  schoolsUpdateEducation?: Maybe<SchoolsEducation>;
  schoolsUpdateExtensionAttribute?: Maybe<SchoolsExtensionAttribute>;
  schoolsUpdateSchool?: Maybe<SchoolsSchool>;
  schoolsUpdateSchoolClass?: Maybe<SchoolsSchoolClass>;
  schoolsUpdateSchoolClassRepresentative?: Maybe<SchoolsSchoolClassRepresentative>;
  schoolsUpdateSchoolPeriod?: Maybe<SchoolsSchoolPeriod>;
  schoolsUpdateSchoolTag?: Maybe<SchoolsSchoolTag>;
  schoolsUpdateSchoolYear?: Maybe<SchoolsSchoolYear>;
  singleUpload?: Maybe<UploadResponse>;
};


export type MutationConfiguratorBatchExportModelPresetsArgs = {
  model: Scalars['ID'];
};


export type MutationConfiguratorConfigurationUpdateSelectionArgs = {
  code: Scalars['ID'];
  path: Scalars['String'];
  selection: Scalars['String'];
};


export type MutationConfiguratorCreateNodeArgs = {
  node_attributes: Scalars['String'];
  parent_id?: InputMaybe<Scalars['Int']>;
};


export type MutationConfiguratorCreatePresetArgs = {
  active: Scalars['Boolean'];
  internal_name: Scalars['String'];
  parent_model_id: Scalars['Int'];
  public_name: Scalars['String'];
  published: Scalars['Boolean'];
};


export type MutationConfiguratorCreatePresetNodeArgs = {
  definition: Scalars['String'];
  parent_node_id: Scalars['Int'];
  preset_id: Scalars['Int'];
  removed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationConfiguratorDeleteConfigurationArgs = {
  code: Scalars['ID'];
};


export type MutationConfiguratorDeleteNodeArgs = {
  id: Scalars['ID'];
};


export type MutationConfiguratorDeletePresetArgs = {
  id: Scalars['ID'];
};


export type MutationConfiguratorDeletePresetNodeArgs = {
  id: Scalars['ID'];
};


export type MutationConfiguratorDuplicateModelArgs = {
  destination: Scalars['String'];
  source: Scalars['Int'];
};


export type MutationConfiguratorDuplicatePresetArgs = {
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  public_name: Scalars['String'];
};


export type MutationConfiguratorExportModelArgs = {
  id: Scalars['ID'];
  preset?: InputMaybe<Scalars['Int']>;
  upgrade_type?: InputMaybe<ConfiguratorSemanticVersion>;
};


export type MutationConfiguratorForgotPasswordArgs = {
  input: ConfiguratorForgotPasswordInput;
};


export type MutationConfiguratorImportJsonModelArgs = {
  json?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorLoginArgs = {
  input?: InputMaybe<ConfiguratorLoginInput>;
};


export type MutationConfiguratorMakeDirArgs = {
  add: Scalars['String'];
  from?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorMoveNodeArgs = {
  id: Scalars['ID'];
  positions?: InputMaybe<Scalars['Int']>;
};


export type MutationConfiguratorRefreshTokenArgs = {
  input?: InputMaybe<ConfiguratorRefreshTokenInput>;
};


export type MutationConfiguratorRegisterArgs = {
  input?: InputMaybe<ConfiguratorRegisterInput>;
};


export type MutationConfiguratorRemoveDirArgs = {
  from?: InputMaybe<Scalars['String']>;
  remove: Scalars['String'];
};


export type MutationConfiguratorRemoveFileArgs = {
  from?: InputMaybe<Scalars['String']>;
  remove: Scalars['String'];
};


export type MutationConfiguratorRemoveMyDesignArgs = {
  configuration_code: Scalars['String'];
  customer_id: Scalars['Int'];
};


export type MutationConfiguratorSaveConfigurationArgs = {
  configuration: Scalars['String'];
  model?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
  renderStack: Scalars['String'];
  shallow?: InputMaybe<Scalars['Boolean']>;
  skip_validation?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorSaveMyDesignArgs = {
  configuration_code: Scalars['String'];
  customer_id: Scalars['Int'];
  ecommerce_data: ConfiguratorEcommerceDataParameter;
};


export type MutationConfiguratorUpdateForgottenPasswordArgs = {
  input: ConfiguratorNewPasswordWithCodeInput;
};


export type MutationConfiguratorUpdateMyDesignArgs = {
  customer_id: Scalars['Int'];
  new_code: Scalars['String'];
  old_code: Scalars['String'];
};


export type MutationConfiguratorUpdateNodeArgs = {
  id: Scalars['ID'];
  node_attributes?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['Int']>;
};


export type MutationConfiguratorUpdatePresetArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_name?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
};


export type MutationConfiguratorUpdatePresetNodeArgs = {
  definition?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationConfiguratorUploadConfigurationMediaArgs = {
  file: Scalars['configuratorUpload'];
  jsonpath: Scalars['String'];
  model: Scalars['String'];
};


export type MutationConfiguratorUploadModelMediaArgs = {
  file: Scalars['configuratorUpload'];
  fileName: Scalars['String'];
  path: Scalars['String'];
};


export type MutationIndexSchoolArgs = {
  schoolId: Scalars['String'];
};


export type MutationPackagesCreatePackageArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id: Scalars['String'];
  base_price?: InputMaybe<Scalars['Float']>;
  definition: Scalars['String'];
  final_price: Scalars['Float'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  overlay_text_bottom?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name: Scalars['String'];
  short_description?: InputMaybe<Scalars['String']>;
  type: PackagesPackageType;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesCreatePackageGalleryItemArgs = {
  alt: Scalars['String'];
  package_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
};


export type MutationPackagesCreatePackageGroupArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  base_price?: InputMaybe<Scalars['Float']>;
  collection_id: Scalars['ID'];
  final_price: Scalars['Float'];
  internal_name: Scalars['String'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  is_free_design?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name: Scalars['String'];
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesCreatePackageGroupCollectionArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  internal_name: Scalars['String'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  public_name: Scalars['String'];
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesCreatePackageGroupCollectionGalleryItemArgs = {
  alt: Scalars['String'];
  collection_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
};


export type MutationPackagesCreatePackageGroupGalleryItemArgs = {
  alt: Scalars['String'];
  group_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
};


export type MutationPackagesCreatePackageGroupPackageArgs = {
  group_id: Scalars['Int'];
  package_id: Scalars['Int'];
};


export type MutationPackagesCreatePackageGroupPresetArgs = {
  code: Scalars['String'];
  group_id: Scalars['Int'];
  sku: Scalars['String'];
};


export type MutationPackagesCreatePackageProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  comparison_label?: InputMaybe<Scalars['String']>;
  definition?: InputMaybe<Scalars['String']>;
  included_options?: InputMaybe<Scalars['String']>;
  is_included?: InputMaybe<Scalars['Boolean']>;
  is_main?: InputMaybe<Scalars['Boolean']>;
  package_id: Scalars['Int'];
  preset_code?: InputMaybe<Scalars['String']>;
  root_model?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  sku: Scalars['String'];
  type: PackagesPackageProductType;
};


export type MutationPackagesDeletePackageArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGalleryItemArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupCollectionGalleryItemArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupGalleryItemArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageGroupPackageArgs = {
  group_id?: InputMaybe<Scalars['Int']>;
  package_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPackagesDeletePackageGroupPresetArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDeletePackageProductArgs = {
  id: Scalars['ID'];
};


export type MutationPackagesDuplicatePackageArgs = {
  ajat_id: Scalars['String'];
  id: Scalars['ID'];
  public_name: Scalars['String'];
};


export type MutationPackagesDuplicatePackageGroupArgs = {
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  public_name: Scalars['String'];
};


export type MutationPackagesSavePackageSelectionArgs = {
  code?: InputMaybe<Scalars['String']>;
  selection: Scalars['String'];
};


export type MutationPackagesUpdatePackageArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  base_price?: InputMaybe<Scalars['Float']>;
  definition?: InputMaybe<Scalars['String']>;
  final_price?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  overlay_text_bottom?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGalleryItemArgs = {
  alt?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  package_id?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  base_price?: InputMaybe<Scalars['Float']>;
  collection_id?: InputMaybe<Scalars['ID']>;
  final_price?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  is_free_design?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupCollectionArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  usps?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupCollectionGalleryItemArgs = {
  alt?: InputMaybe<Scalars['String']>;
  collection_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupGalleryItemArgs = {
  alt?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageGroupPackageArgs = {
  group_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  package_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPackagesUpdatePackageGroupPresetArgs = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sku?: InputMaybe<Scalars['String']>;
};


export type MutationPackagesUpdatePackageProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  comparison_label?: InputMaybe<Scalars['String']>;
  definition?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  included_options?: InputMaybe<Scalars['String']>;
  is_included?: InputMaybe<Scalars['Boolean']>;
  is_main?: InputMaybe<Scalars['Boolean']>;
  preset_code?: InputMaybe<Scalars['String']>;
  root_model?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PackagesPackageProductType>;
};


export type MutationPersonalizationCreateRuleArgs = {
  actions: Scalars['String'];
  active?: InputMaybe<Scalars['Boolean']>;
  break?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  rules: Scalars['String'];
};


export type MutationPersonalizationDeleteRuleArgs = {
  id: Scalars['ID'];
};


export type MutationPersonalizationDuplicateRuleArgs = {
  id: Scalars['ID'];
};


export type MutationPersonalizationUpdateRuleArgs = {
  actions?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  break?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  rules?: InputMaybe<Scalars['String']>;
};


export type MutationPersonalizationUpdateRulesPathsArgs = {
  updates: Array<PersonalizationRulePathUpdateInput>;
};


export type MutationSchoolsCreateEducationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  name: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};


export type MutationSchoolsCreateExtensionAttributeArgs = {
  attribute_backend_type: Scalars['String'];
  attribute_code: Scalars['String'];
  attribute_frontend_type: Scalars['String'];
  attribute_label: Scalars['String'];
  entity_type: Scalars['String'];
};


export type MutationSchoolsCreateSchoolArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  ajat_id: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['schoolsJSON']>;
  hero_banner?: InputMaybe<Scalars['String']>;
  internal_note?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_2?: InputMaybe<Scalars['String']>;
  logo_3?: InputMaybe<Scalars['String']>;
  logo_4?: InputMaybe<Scalars['String']>;
  marketing_url?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  personalization_logo?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  representative_discount_amount?: InputMaybe<Scalars['Float']>;
  representative_discount_percentage?: InputMaybe<Scalars['Float']>;
  representative_email?: InputMaybe<Scalars['String']>;
  representative_name?: InputMaybe<Scalars['String']>;
  representative_ssn?: InputMaybe<Scalars['String']>;
  representative_telephone?: InputMaybe<Scalars['String']>;
  sales_responsible?: InputMaybe<Scalars['String']>;
  sales_team?: InputMaybe<Scalars['String']>;
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationSchoolsCreateSchoolClassArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['schoolsJSON']>;
  name: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  school_id: Scalars['Int'];
  school_year_id: Scalars['Int'];
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationSchoolsCreateSchoolClassRepresentativeArgs = {
  discount_amount?: InputMaybe<Scalars['Float']>;
  discount_percentage?: InputMaybe<Scalars['Float']>;
  discount_used?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  name: Scalars['String'];
  ssn?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationSchoolsCreateSchoolClassSchoolClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  school_class_id: Scalars['Int'];
};


export type MutationSchoolsCreateSchoolClassSchoolTagArgs = {
  school_class_id: Scalars['Int'];
  school_tag_id: Scalars['Int'];
};


export type MutationSchoolsCreateSchoolEducationArgs = {
  education_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type MutationSchoolsCreateSchoolPeriodArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  from_date: Scalars['schoolsDate'];
  grace_payment_date?: InputMaybe<Scalars['schoolsDate']>;
  school_id: Scalars['Int'];
  to_date: Scalars['schoolsDate'];
};


export type MutationSchoolsCreateSchoolSchoolClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type MutationSchoolsCreateSchoolSchoolTagArgs = {
  school_id: Scalars['Int'];
  school_tag_id: Scalars['Int'];
};


export type MutationSchoolsCreateSchoolTagArgs = {
  tag: Scalars['String'];
};


export type MutationSchoolsCreateSchoolYearArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationSchoolsDeleteEducationArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteExtensionAttributeArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteSchoolArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteSchoolClassArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteSchoolClassRepresentativeArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteSchoolClassSchoolClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  school_class_id: Scalars['Int'];
};


export type MutationSchoolsDeleteSchoolClassSchoolTagArgs = {
  school_class_id: Scalars['Int'];
  school_tag_id: Scalars['Int'];
};


export type MutationSchoolsDeleteSchoolEducationArgs = {
  education_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type MutationSchoolsDeleteSchoolPeriodArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteSchoolSchoolClassRepresentativeArgs = {
  representative_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type MutationSchoolsDeleteSchoolSchoolTagArgs = {
  school_id: Scalars['Int'];
  school_tag_id: Scalars['Int'];
};


export type MutationSchoolsDeleteSchoolTagArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsDeleteSchoolYearArgs = {
  id: Scalars['ID'];
};


export type MutationSchoolsImportSchoolClassRepresentativesArgs = {
  file: Scalars['schoolsUpload'];
};


export type MutationSchoolsImportSchoolClassesArgs = {
  file: Scalars['schoolsUpload'];
};


export type MutationSchoolsImportSchoolsArgs = {
  file: Scalars['schoolsUpload'];
};


export type MutationSchoolsUpdateEducationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};


export type MutationSchoolsUpdateExtensionAttributeArgs = {
  attribute_backend_type?: InputMaybe<Scalars['String']>;
  attribute_code?: InputMaybe<Scalars['String']>;
  attribute_frontend_type?: InputMaybe<Scalars['String']>;
  attribute_label?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationSchoolsUpdateSchoolArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['schoolsJSON']>;
  hero_banner?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_note?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_2?: InputMaybe<Scalars['String']>;
  logo_3?: InputMaybe<Scalars['String']>;
  logo_4?: InputMaybe<Scalars['String']>;
  marketing_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  personalization_logo?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  representative_discount_amount?: InputMaybe<Scalars['Float']>;
  representative_discount_percentage?: InputMaybe<Scalars['Float']>;
  representative_email?: InputMaybe<Scalars['String']>;
  representative_name?: InputMaybe<Scalars['String']>;
  representative_ssn?: InputMaybe<Scalars['String']>;
  representative_telephone?: InputMaybe<Scalars['String']>;
  sales_responsible?: InputMaybe<Scalars['String']>;
  sales_team?: InputMaybe<Scalars['String']>;
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationSchoolsUpdateSchoolClassArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  embroidery_1?: InputMaybe<Scalars['String']>;
  embroidery_2?: InputMaybe<Scalars['String']>;
  embroidery_3?: InputMaybe<Scalars['String']>;
  extension_attributes?: InputMaybe<Scalars['schoolsJSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  school_id?: InputMaybe<Scalars['Int']>;
  school_year_id?: InputMaybe<Scalars['Int']>;
  students_amount?: InputMaybe<Scalars['Int']>;
};


export type MutationSchoolsUpdateSchoolClassRepresentativeArgs = {
  discount_amount?: InputMaybe<Scalars['Float']>;
  discount_percentage?: InputMaybe<Scalars['Float']>;
  discount_used?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationSchoolsUpdateSchoolPeriodArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  from_date: Scalars['schoolsDate'];
  grace_payment_date?: InputMaybe<Scalars['schoolsDate']>;
  id: Scalars['ID'];
  to_date: Scalars['schoolsDate'];
};


export type MutationSchoolsUpdateSchoolTagArgs = {
  id: Scalars['ID'];
  tag: Scalars['String'];
};


export type MutationSchoolsUpdateSchoolYearArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
  fileName: Scalars['String'];
  path: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  configuratorCameraPresets?: Maybe<ConfiguratorCameraPresetResults>;
  configuratorConfiguration?: Maybe<ConfiguratorConfigurationResult>;
  configuratorConfigurationFilter?: Maybe<ConfiguratorConfigurationFilterResult>;
  configuratorConfigurationHasRequiredSelections?: Maybe<ConfiguratorConfigurationHasRequiredSelectionResult>;
  configuratorConfigurationHasSelection?: Maybe<ConfiguratorConfigurationHasSelectionResult>;
  configuratorDefaultConfigurationByPreset?: Maybe<Scalars['String']>;
  configuratorExportJSONModel?: Maybe<ConfiguratorJsonExport>;
  configuratorFlattenConfiguration?: Maybe<Scalars['String']>;
  configuratorGalleryMediaUrl?: Maybe<Scalars['String']>;
  configuratorGalleryStructure?: Maybe<Scalars['String']>;
  configuratorGetAttributesTreeByModelNode?: Maybe<Scalars['String']>;
  configuratorGetAttributesTreeByModelNodeType?: Maybe<Scalars['String']>;
  configuratorGetMyDesignByConfiguration?: Maybe<Array<Maybe<ConfiguratorMyDesign>>>;
  configuratorIsMyDesign?: Maybe<Scalars['Boolean']>;
  configuratorModel?: Maybe<ConfiguratorModel>;
  configuratorModelMedia?: Maybe<Scalars['String']>;
  configuratorModelSchema?: Maybe<Scalars['String']>;
  configuratorModelTreeStructure?: Maybe<Scalars['String']>;
  configuratorNode?: Maybe<ConfiguratorNode>;
  configuratorNodeOverrideDryRun?: Maybe<Scalars['String']>;
  configuratorNodeOverrideSchema?: Maybe<Scalars['String']>;
  configuratorNodes: Array<ConfiguratorNode>;
  configuratorPreset?: Maybe<ConfiguratorPreset>;
  configuratorPresetNode?: Maybe<ConfiguratorPresetNode>;
  configuratorPresetNodes?: Maybe<ConfiguratorPresetNodePaginator>;
  configuratorPresets?: Maybe<ConfiguratorPresetPaginator>;
  configuratorRenderStack?: Maybe<ConfiguratorRenderStackResult>;
  configuratorUiSchema?: Maybe<Scalars['String']>;
  configuratorUser?: Maybe<ConfiguratorUser>;
  configuratorUsers?: Maybe<ConfiguratorUserPaginator>;
  configuratorValidateConfiguration?: Maybe<Scalars['Boolean']>;
  otherFields: Scalars['Boolean'];
  packagesAvailableProducts?: Maybe<Array<Maybe<PackagesAvailableProduct>>>;
  packagesGetMediaUrl?: Maybe<Scalars['String']>;
  packagesPackage?: Maybe<PackagesPackage>;
  packagesPackageGalleryItem?: Maybe<PackagesPackageGalleryItem>;
  packagesPackageGalleryItems?: Maybe<PackagesPackageGalleryItemPaginator>;
  packagesPackageGroup?: Maybe<PackagesPackageGroup>;
  packagesPackageGroupCollection?: Maybe<PackagesPackageGroupCollection>;
  packagesPackageGroupCollectionGalleryItem?: Maybe<PackagesPackageGroupCollectionGalleryItem>;
  packagesPackageGroupCollectionGalleryItems?: Maybe<PackagesPackageGroupCollectionGalleryItemPaginator>;
  packagesPackageGroupCollections?: Maybe<PackagesPackageGroupCollectionPaginator>;
  packagesPackageGroupGalleryItem?: Maybe<PackagesPackageGroupGalleryItem>;
  packagesPackageGroupGalleryItems?: Maybe<PackagesPackageGroupGalleryItemPaginator>;
  packagesPackageGroups?: Maybe<PackagesPackageGroupPaginator>;
  packagesPackageProduct?: Maybe<PackagesPackageProduct>;
  packagesPackageProducts?: Maybe<PackagesPackageProductPaginator>;
  packagesPackageSchema?: Maybe<Scalars['String']>;
  packagesPackageSelection?: Maybe<PackagesSavedPackage>;
  packagesPackages?: Maybe<PackagesPackagePaginator>;
  packagesValidateSelection?: Maybe<Scalars['Boolean']>;
  personalizationActionConfig?: Maybe<Scalars['String']>;
  personalizationAvailableMentions?: Maybe<Scalars['String']>;
  personalizationProcessRules?: Maybe<Scalars['String']>;
  personalizationRule?: Maybe<PersonalizationRule>;
  personalizationRuleBuilderConfig?: Maybe<Scalars['String']>;
  personalizationRules?: Maybe<PersonalizationRulePaginator>;
  roles?: Maybe<Array<Role>>;
  schoolsCountries?: Maybe<Array<Maybe<SchoolsCountry>>>;
  schoolsEducation?: Maybe<SchoolsEducation>;
  schoolsEducations?: Maybe<SchoolsEducationPaginator>;
  schoolsExtensionAttribute?: Maybe<SchoolsExtensionAttribute>;
  schoolsExtensionAttributes?: Maybe<SchoolsExtensionAttributePaginator>;
  schoolsExtensionAttributesSchema?: Maybe<Scalars['String']>;
  schoolsGalleryMediaUrl?: Maybe<Scalars['String']>;
  schoolsSchool?: Maybe<SchoolsSchool>;
  schoolsSchoolByAjatId?: Maybe<SchoolsSchool>;
  schoolsSchoolClass?: Maybe<SchoolsSchoolClass>;
  schoolsSchoolClassByCodes?: Maybe<SchoolsSchoolClass>;
  schoolsSchoolClassRepresentative?: Maybe<SchoolsSchoolClassRepresentative>;
  schoolsSchoolClassRepresentatives?: Maybe<SchoolsSchoolClassRepresentativePaginator>;
  schoolsSchoolClassSchoolTags?: Maybe<SchoolsSchoolClassSchoolTagPaginator>;
  schoolsSchoolClasses?: Maybe<SchoolsSchoolClassPaginator>;
  schoolsSchoolEducations?: Maybe<SchoolsSchoolEducationPaginator>;
  schoolsSchoolPeriod?: Maybe<SchoolsSchoolPeriod>;
  schoolsSchoolPeriods?: Maybe<SchoolsSchoolPeriodPaginator>;
  schoolsSchoolSalesResponsibles?: Maybe<SchoolsSchoolSalesResponsiblePaginator>;
  schoolsSchoolSalesTeams?: Maybe<SchoolsSchoolSalesTeamPaginator>;
  schoolsSchoolSchoolTags?: Maybe<SchoolsSchoolSchoolTagPaginator>;
  schoolsSchoolTag?: Maybe<SchoolsSchoolTag>;
  schoolsSchoolTags?: Maybe<SchoolsSchoolTagPaginator>;
  schoolsSchoolYear?: Maybe<SchoolsSchoolYear>;
  schoolsSchoolYears?: Maybe<SchoolsSchoolYearPaginator>;
  schoolsSchools?: Maybe<SchoolsSchoolPaginator>;
};


export type QueryConfiguratorCameraPresetsArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorConfigurationArgs = {
  code: Scalars['ID'];
  shallow?: InputMaybe<Scalars['Boolean']>;
};


export type QueryConfiguratorConfigurationFilterArgs = {
  filter?: InputMaybe<Array<InputMaybe<ConfiguratorConfigurationFilter>>>;
  from?: InputMaybe<Scalars['Int']>;
  modelCode?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['Float']>;
};


export type QueryConfiguratorConfigurationHasRequiredSelectionsArgs = {
  code: Scalars['ID'];
};


export type QueryConfiguratorConfigurationHasSelectionArgs = {
  code: Scalars['ID'];
  path: Scalars['String'];
};


export type QueryConfiguratorDefaultConfigurationByPresetArgs = {
  code: Scalars['String'];
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorExportJsonModelArgs = {
  include_presets?: InputMaybe<Scalars['Boolean']>;
  node_id: Scalars['ID'];
};


export type QueryConfiguratorFlattenConfigurationArgs = {
  code: Scalars['ID'];
};


export type QueryConfiguratorGalleryMediaUrlArgs = {
  rel?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorGalleryStructureArgs = {
  from?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorGetAttributesTreeByModelNodeArgs = {
  model: Scalars['String'];
  modelNode: Scalars['String'];
};


export type QueryConfiguratorGetAttributesTreeByModelNodeTypeArgs = {
  modelNodeType: Scalars['String'];
};


export type QueryConfiguratorGetMyDesignByConfigurationArgs = {
  configuration_code: Scalars['String'];
};


export type QueryConfiguratorIsMyDesignArgs = {
  configuration_code: Scalars['String'];
  customer_id: Scalars['Int'];
};


export type QueryConfiguratorModelArgs = {
  code: Scalars['ID'];
  model?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorModelMediaArgs = {
  code: Scalars['ID'];
  version: Scalars['String'];
};


export type QueryConfiguratorModelSchemaArgs = {
  model?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorModelTreeStructureArgs = {
  code: Scalars['ID'];
};


export type QueryConfiguratorNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorNodeOverrideDryRunArgs = {
  node_id: Scalars['Int'];
};


export type QueryConfiguratorNodeOverrideSchemaArgs = {
  model: Scalars['String'];
  node_type: ConfiguratorNodeType;
};


export type QueryConfiguratorNodesArgs = {
  parent_id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorPresetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorPresetNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorPresetNodesArgs = {
  filter?: InputMaybe<ConfiguratorPresetNodeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfiguratorQueryPresetNodesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryConfiguratorPresetsArgs = {
  filter?: InputMaybe<ConfiguratorPresetFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfiguratorQueryPresetsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryConfiguratorRenderStackArgs = {
  configurationCode: Scalars['ID'];
};


export type QueryConfiguratorUiSchemaArgs = {
  model?: InputMaybe<Scalars['String']>;
};


export type QueryConfiguratorUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConfiguratorUsersArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryConfiguratorValidateConfigurationArgs = {
  configuration: Scalars['String'];
  model: Scalars['String'];
  overrides?: InputMaybe<Array<InputMaybe<ConfiguratorModelPathOverride>>>;
};


export type QueryPackagesGetMediaUrlArgs = {
  rel: Scalars['String'];
};


export type QueryPackagesPackageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGalleryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGalleryItemsArgs = {
  filter?: InputMaybe<PackagesPackageGalleryItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGalleryItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupCollectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupCollectionGalleryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupCollectionGalleryItemsArgs = {
  filter?: InputMaybe<PackagesPackageGroupCollectionGalleryItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupCollectionGalleryItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupCollectionsArgs = {
  filter?: InputMaybe<PackagesPackageGroupCollectionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupCollectionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupGalleryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageGroupGalleryItemsArgs = {
  filter?: InputMaybe<PackagesPackageGroupGalleryItemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupGalleryItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageGroupsArgs = {
  filter?: InputMaybe<PackagesPackageGroupFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageGroupsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPackagesPackageProductsArgs = {
  filter?: InputMaybe<PackagesPackageProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackageProductsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesPackageSchemaArgs = {
  type: PackagesPackageType;
};


export type QueryPackagesPackageSelectionArgs = {
  code: Scalars['String'];
};


export type QueryPackagesPackagesArgs = {
  filter?: InputMaybe<PackagesPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PackagesQueryPackagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPackagesValidateSelectionArgs = {
  code: Scalars['String'];
};


export type QueryPersonalizationAvailableMentionsArgs = {
  type?: InputMaybe<PersonalizationAvailableMentionsTypes>;
  withEntity?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPersonalizationProcessRulesArgs = {
  data: Scalars['String'];
};


export type QueryPersonalizationRuleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPersonalizationRulesArgs = {
  filter?: InputMaybe<PersonalizationRuleFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PersonalizationQueryRulesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRolesArgs = {
  user_id: Scalars['String'];
};


export type QuerySchoolsEducationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsEducationsArgs = {
  filter?: InputMaybe<SchoolsEducationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQueryEducationsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsExtensionAttributeArgs = {
  id: Scalars['ID'];
};


export type QuerySchoolsExtensionAttributesArgs = {
  filter?: InputMaybe<SchoolsExtensionAttributeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQueryExtensionAttributesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsExtensionAttributesSchemaArgs = {
  entity_type: Scalars['String'];
};


export type QuerySchoolsGalleryMediaUrlArgs = {
  rel?: InputMaybe<Scalars['String']>;
};


export type QuerySchoolsSchoolArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsSchoolByAjatIdArgs = {
  ajat_id: Scalars['String'];
};


export type QuerySchoolsSchoolClassArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsSchoolClassByCodesArgs = {
  class_code: Scalars['String'];
  school?: InputMaybe<SchoolsQuerySchoolClassByCodesSchoolWhereHasConditions>;
};


export type QuerySchoolsSchoolClassRepresentativeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsSchoolClassRepresentativesArgs = {
  filter?: InputMaybe<SchoolsSchoolClassRepresentativeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolClassRepresentativesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SchoolsQuerySchoolClassRepresentativesWhereWhereConditions>;
};


export type QuerySchoolsSchoolClassSchoolTagsArgs = {
  filter?: InputMaybe<SchoolsSchoolClassSchoolTagFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolClassSchoolTagsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsSchoolClassesArgs = {
  filter?: InputMaybe<SchoolsSchoolClassFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolClassesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SchoolsQuerySchoolClassesWhereWhereConditions>;
};


export type QuerySchoolsSchoolEducationsArgs = {
  filter?: InputMaybe<SchoolsSchoolEducationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolEducationsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsSchoolPeriodArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsSchoolPeriodsArgs = {
  filter?: InputMaybe<SchoolsSchoolPeriodFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolPeriodsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsSchoolSalesResponsiblesArgs = {
  filter?: InputMaybe<SchoolsSchoolSalesResponsibleFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolSalesResponsiblesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SchoolsQuerySchoolSalesResponsiblesWhereWhereConditions>;
};


export type QuerySchoolsSchoolSalesTeamsArgs = {
  filter?: InputMaybe<SchoolsSchoolSalesTeamFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolSalesTeamsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SchoolsQuerySchoolSalesTeamsWhereWhereConditions>;
};


export type QuerySchoolsSchoolSchoolTagsArgs = {
  filter?: InputMaybe<SchoolsSchoolSchoolTagFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolSchoolTagsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsSchoolTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsSchoolTagsArgs = {
  filter?: InputMaybe<SchoolsSchoolTagFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolTagsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsSchoolYearArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySchoolsSchoolYearsArgs = {
  filter?: InputMaybe<SchoolsSchoolYearFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolYearsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySchoolsSchoolsArgs = {
  filter?: InputMaybe<SchoolsSchoolFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsQuerySchoolsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SchoolsQuerySchoolsWhereWhereConditions>;
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type StackTrace = {
  __typename?: 'StackTrace';
  call?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['Int']>;
};

export type UploadData = {
  __typename?: 'UploadData';
  uploadModelMedia?: Maybe<File>;
};

export type UploadResponse = {
  __typename?: 'UploadResponse';
  data?: Maybe<UploadData>;
  errors?: Maybe<Array<Maybe<ErrorArrayEntry>>>;
};

export type ConfiguratorAuthPayload = {
  __typename?: 'configuratorAuthPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
  user: ConfiguratorUser;
};

export type ConfiguratorCameraPresetResult = {
  __typename?: 'configuratorCameraPresetResult';
  dolly?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  snapshotParams?: Maybe<ConfiguratorCameraPresetResultSnapshotParams>;
  useInCameraBar?: Maybe<Scalars['Boolean']>;
  useToSwitch?: Maybe<Scalars['Boolean']>;
  xang?: Maybe<Scalars['Float']>;
  xpan?: Maybe<Scalars['Float']>;
  yang?: Maybe<Scalars['Float']>;
  ypan?: Maybe<Scalars['Float']>;
};

export type ConfiguratorCameraPresetResultSnapshotParams = {
  __typename?: 'configuratorCameraPresetResultSnapshotParams';
  excludeNodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeNodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConfiguratorCameraPresetResults = {
  __typename?: 'configuratorCameraPresetResults';
  defaultSnapshotCode?: Maybe<Scalars['String']>;
  defaultSnapshotParams?: Maybe<ConfiguratorCameraPresetResultSnapshotParams>;
  presets?: Maybe<Array<Maybe<ConfiguratorCameraPresetResult>>>;
};

export type ConfiguratorConfigurationDetailGroup = {
  __typename?: 'configuratorConfigurationDetailGroup';
  code?: Maybe<Scalars['String']>;
  configurations?: Maybe<Array<Maybe<ConfiguratorConfigurationDetailGroupSelection>>>;
  icon?: Maybe<Scalars['Boolean']>;
  internal_label?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  pricing_info?: Maybe<ConfiguratorDetailPricingInfo>;
};

export type ConfiguratorConfigurationDetailGroupSelection = {
  __typename?: 'configuratorConfigurationDetailGroupSelection';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  pricing_info?: Maybe<ConfiguratorDetailPricingInfo>;
  value?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationFilter = {
  code: Scalars['String'];
  value: Scalars['String'];
};

export type ConfiguratorConfigurationFilterResult = {
  __typename?: 'configuratorConfigurationFilterResult';
  aggregations?: Maybe<Scalars['String']>;
  hits?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationGalleryItem = {
  file: Scalars['configuratorUpload'];
  type: ConfiguratorConfigurationGalleryItemType;
};

export enum ConfiguratorConfigurationGalleryItemType {
  Detail = 'detail',
  Main = 'main'
}

export type ConfiguratorConfigurationHasRequiredSelectionResult = {
  __typename?: 'configuratorConfigurationHasRequiredSelectionResult';
  hasRequiredSelections: Scalars['Boolean'];
  missingModelNodes?: Maybe<Array<ConfiguratorMissingModelNodes>>;
};

export type ConfiguratorConfigurationHasSelectionResult = {
  __typename?: 'configuratorConfigurationHasSelectionResult';
  hasValidSelection: Scalars['Boolean'];
  modelNode?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationResult = {
  __typename?: 'configuratorConfigurationResult';
  body?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  details?: Maybe<Array<Maybe<ConfiguratorConfigurationDetailGroup>>>;
  finalPrice?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  overrides?: Maybe<Array<Maybe<ConfiguratorModelPathOverrideResult>>>;
  presetCode?: Maybe<Scalars['String']>;
  snapshotGallery?: Maybe<Array<Maybe<ConfiguratorConfigurationSnapshotResult>>>;
  snapshotImage?: Maybe<Scalars['String']>;
};

export type ConfiguratorConfigurationSnapshotResult = {
  __typename?: 'configuratorConfigurationSnapshotResult';
  imageUrl?: Maybe<Scalars['String']>;
  preset?: Maybe<Scalars['String']>;
};

export type ConfiguratorDetailPricingInfo = {
  __typename?: 'configuratorDetailPricingInfo';
  overrides?: Maybe<Array<Maybe<ConfiguratorDetailPricingInfoOverrideItem>>>;
  price?: Maybe<Scalars['Float']>;
};

export type ConfiguratorDetailPricingInfoOverrideItem = {
  __typename?: 'configuratorDetailPricingInfoOverrideItem';
  path?: Maybe<Scalars['String']>;
  previous_value?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfiguratorEcommerceDataParameter = {
  product_id: Scalars['String'];
};

export type ConfiguratorForgotPasswordInput = {
  email: Scalars['String'];
};

export type ConfiguratorForgotPasswordResponse = {
  __typename?: 'configuratorForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ConfiguratorJsonExport = {
  __typename?: 'configuratorJSONExport';
  model?: Maybe<Scalars['String']>;
  presets?: Maybe<Scalars['String']>;
};

export type ConfiguratorJsonImport = {
  __typename?: 'configuratorJSONImport';
  model_id?: Maybe<Scalars['String']>;
};

export type ConfiguratorLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type ConfiguratorLogoutResponse = {
  __typename?: 'configuratorLogoutResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ConfiguratorMakeDirResult = {
  __typename?: 'configuratorMakeDirResult';
  id?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type ConfiguratorMissingModelNodes = {
  __typename?: 'configuratorMissingModelNodes';
  modelNode: Scalars['String'];
  path: Scalars['String'];
};

export type ConfiguratorModel = {
  __typename?: 'configuratorModel';
  body?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  parent_version?: Maybe<Scalars['String']>;
  pricing?: Maybe<ConfiguratorModelPricing>;
  version?: Maybe<Scalars['String']>;
};

export type ConfiguratorModelMediaResultItem = {
  __typename?: 'configuratorModelMediaResultItem';
  data?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
};

export type ConfiguratorModelPathOverride = {
  path: Scalars['String'];
  tag?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type ConfiguratorModelPathOverrideResult = {
  __typename?: 'configuratorModelPathOverrideResult';
  path: Scalars['String'];
  value: Scalars['String'];
};

export type ConfiguratorModelPricing = {
  __typename?: 'configuratorModelPricing';
  currency?: Maybe<Scalars['String']>;
  special_starting_price?: Maybe<Scalars['Float']>;
  starting_price?: Maybe<Scalars['Float']>;
};

export type ConfiguratorMyDesign = {
  __typename?: 'configuratorMyDesign';
  configuration_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  ecommerce_data?: Maybe<Scalars['String']>;
};

export type ConfiguratorNewPasswordWithCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type ConfiguratorNode = {
  __typename?: 'configuratorNode';
  children: Array<ConfiguratorNode>;
  code?: Maybe<Scalars['String']>;
  created_at: Scalars['configuratorDateTime'];
  descendants_have_nodes_of_presets?: Maybe<Array<Maybe<ConfiguratorNodePresetNode>>>;
  has_nodes_of_presets?: Maybe<Array<Maybe<ConfiguratorNodePresetNode>>>;
  id: Scalars['ID'];
  is_edited?: Maybe<Scalars['Boolean']>;
  node_attributes?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['configuratorDateTime'];
};

export type ConfiguratorNodePresetNode = {
  __typename?: 'configuratorNodePresetNode';
  preset_id: Scalars['Int'];
  preset_node_id: Scalars['Int'];
};

export enum ConfiguratorNodeType {
  Fieldset = 'fieldset',
  Option = 'option',
  Root = 'root',
  Select = 'select',
  String = 'string',
  Tab = 'tab',
  Upload = 'upload'
}

/** Allows ordering a list of records. */
export type ConfiguratorOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum ConfiguratorOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum ConfiguratorOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type ConfiguratorPageInfo = {
  __typename?: 'configuratorPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type ConfiguratorPaginatorInfo = {
  __typename?: 'configuratorPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type ConfiguratorPreset = {
  __typename?: 'configuratorPreset';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['configuratorDateTime'];
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  model: ConfiguratorNode;
  nodes: Array<ConfiguratorPresetNode>;
  parent_model_id: Scalars['Int'];
  public_name: Scalars['String'];
  published: Scalars['Boolean'];
  published_code?: Maybe<Scalars['String']>;
  published_version?: Maybe<Scalars['String']>;
  updated_at: Scalars['configuratorDateTime'];
};


export type ConfiguratorPresetNodesArgs = {
  orderBy?: InputMaybe<Array<ConfiguratorPresetNodesOrderByOrderByClause>>;
};

export type ConfiguratorPresetExportedItem = {
  __typename?: 'configuratorPresetExportedItem';
  error?: Maybe<Scalars['String']>;
  internal_name: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ConfiguratorPresetFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['configuratorDate']>;
  internal_name?: InputMaybe<Scalars['String']>;
  parent_model_id?: InputMaybe<Scalars['Int']>;
  public_name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['configuratorDate']>;
};

export type ConfiguratorPresetNode = {
  __typename?: 'configuratorPresetNode';
  created_at: Scalars['configuratorDateTime'];
  definition: Scalars['String'];
  id: Scalars['ID'];
  parent_node_id: Scalars['Int'];
  preset: ConfiguratorPreset;
  preset_id: Scalars['Int'];
  removed: Scalars['Boolean'];
  updated_at: Scalars['configuratorDateTime'];
};

export type ConfiguratorPresetNodeFilterInput = {
  created_at?: InputMaybe<Scalars['configuratorDate']>;
  parent_node_id?: InputMaybe<Scalars['Int']>;
  preset_id?: InputMaybe<Scalars['Int']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['configuratorDate']>;
};

/** A paginated list of PresetNode items. */
export type ConfiguratorPresetNodePaginator = {
  __typename?: 'configuratorPresetNodePaginator';
  /** A list of PresetNode items. */
  data: Array<ConfiguratorPresetNode>;
  /** Pagination information about the list of items. */
  paginatorInfo: ConfiguratorPaginatorInfo;
};

/** Allowed column names for Preset.nodes.orderBy. */
export enum ConfiguratorPresetNodesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  InternalName = 'INTERNAL_NAME',
  ParentModelId = 'PARENT_MODEL_ID',
  PublicName = 'PUBLIC_NAME',
  Published = 'PUBLISHED',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Preset.nodes.orderBy. */
export type ConfiguratorPresetNodesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConfiguratorPresetNodesOrderByColumn;
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

/** A paginated list of Preset items. */
export type ConfiguratorPresetPaginator = {
  __typename?: 'configuratorPresetPaginator';
  /** A list of Preset items. */
  data: Array<ConfiguratorPreset>;
  /** Pagination information about the list of items. */
  paginatorInfo: ConfiguratorPaginatorInfo;
};

/** Allowed column names for Query.presetNodes.orderBy. */
export enum ConfiguratorQueryPresetNodesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  ParentNode = 'PARENT_NODE',
  PresetId = 'PRESET_ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.presetNodes.orderBy. */
export type ConfiguratorQueryPresetNodesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConfiguratorQueryPresetNodesOrderByColumn;
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

/** Allowed column names for Query.presets.orderBy. */
export enum ConfiguratorQueryPresetsOrderByColumn {
  Active = 'ACTIVE',
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  InternalName = 'INTERNAL_NAME',
  ParentModelId = 'PARENT_MODEL_ID',
  PublicName = 'PUBLIC_NAME',
  Published = 'PUBLISHED',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.presets.orderBy. */
export type ConfiguratorQueryPresetsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConfiguratorQueryPresetsOrderByColumn;
  /** The direction that is used for ordering. */
  order: ConfiguratorSortOrder;
};

export type ConfiguratorRefreshTokenInput = {
  refresh_token?: InputMaybe<Scalars['String']>;
};

export type ConfiguratorRegisterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type ConfiguratorRenderStackResult = {
  __typename?: 'configuratorRenderStackResult';
  code?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['String']>;
};

/** Semantic versioning options */
export enum ConfiguratorSemanticVersion {
  Major = 'major',
  Minor = 'minor',
  Patch = 'patch'
}

/** Information about pagination using a simple paginator. */
export type ConfiguratorSimplePaginatorInfo = {
  __typename?: 'configuratorSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum ConfiguratorSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum ConfiguratorTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type ConfiguratorUploadModelMediaResult = {
  __typename?: 'configuratorUploadModelMediaResult';
  relativePath?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ConfiguratorUser = {
  __typename?: 'configuratorUser';
  created_at: Scalars['configuratorDateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['configuratorDateTime'];
};

/** A paginated list of User items. */
export type ConfiguratorUserPaginator = {
  __typename?: 'configuratorUserPaginator';
  /** A list of User items. */
  data: Array<ConfiguratorUser>;
  /** Pagination information about the list of items. */
  paginatorInfo: ConfiguratorPaginatorInfo;
};

export type PackagesAvailableProduct = {
  __typename?: 'packagesAvailableProduct';
  name: Scalars['String'];
  pricing?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  type: Scalars['String'];
};

/** Allows ordering a list of records. */
export type PackagesOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum PackagesOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum PackagesOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type PackagesPackage = {
  __typename?: 'packagesPackage';
  active: Scalars['Boolean'];
  ajat_id: Scalars['String'];
  base_price?: Maybe<Scalars['Float']>;
  created_at: Scalars['packagesDateTime'];
  definition: Scalars['String'];
  final_price: Scalars['Float'];
  gallery?: Maybe<Array<PackagesPackageGalleryItem>>;
  groups?: Maybe<Array<PackagesPackageGroup>>;
  id: Scalars['ID'];
  is_default: Scalars['Boolean'];
  is_favourite: Scalars['Boolean'];
  long_description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  overlay_text?: Maybe<Scalars['String']>;
  overlay_text_bottom?: Maybe<Scalars['String']>;
  price_tooltip?: Maybe<Scalars['String']>;
  products: Array<PackagesPackageProduct>;
  public_name: Scalars['String'];
  short_description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  wp_slug?: Maybe<Scalars['String']>;
};


export type PackagesPackageGroupsArgs = {
  filter?: InputMaybe<PackagesPackageGroupFilterInput>;
};


export type PackagesPackageProductsArgs = {
  filter?: InputMaybe<PackagesPackageProductFilterInput>;
};

export type PackagesPackageFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  base_price?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  final_price?: InputMaybe<Scalars['Float']>;
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  overlay_text_bottom?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PackagesPackageType>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};

export type PackagesPackageGalleryItem = {
  __typename?: 'packagesPackageGalleryItem';
  alt: Scalars['String'];
  created_at: Scalars['packagesDateTime'];
  id: Scalars['ID'];
  package_id: Scalars['Int'];
  path: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  url?: Maybe<Scalars['String']>;
};

export type PackagesPackageGalleryItemFilterInput = {
  created_at?: InputMaybe<Scalars['packagesDate']>;
  package_id?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

/** A paginated list of PackageGalleryItem items. */
export type PackagesPackageGalleryItemPaginator = {
  __typename?: 'packagesPackageGalleryItemPaginator';
  /** A list of PackageGalleryItem items. */
  data: Array<PackagesPackageGalleryItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroup = {
  __typename?: 'packagesPackageGroup';
  active: Scalars['Boolean'];
  base_price?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  collection_id: Scalars['Int'];
  created_at: Scalars['packagesDateTime'];
  final_price: Scalars['Float'];
  gallery?: Maybe<Array<PackagesPackageGroupGalleryItem>>;
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  is_default: Scalars['Boolean'];
  is_favourite: Scalars['Boolean'];
  is_free_design: Scalars['Boolean'];
  long_description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  overlay_text?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<PackagesPackage>>;
  presets?: Maybe<Array<PackagesPackageGroupPreset>>;
  price_tooltip?: Maybe<Scalars['String']>;
  public_name: Scalars['String'];
  short_description?: Maybe<Scalars['String']>;
  updated_at: Scalars['packagesDateTime'];
  usps?: Maybe<Scalars['String']>;
  wp_slug?: Maybe<Scalars['String']>;
};


export type PackagesPackageGroupPackagesArgs = {
  filter?: InputMaybe<PackagesPackageFilterInput>;
  orderBy?: InputMaybe<Array<PackagesPackageGroupPackagesOrderByOrderByClause>>;
};

export type PackagesPackageGroupCollection = {
  __typename?: 'packagesPackageGroupCollection';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['packagesDateTime'];
  gallery?: Maybe<Array<PackagesPackageGroupCollectionGalleryItem>>;
  groups?: Maybe<Array<PackagesPackageGroup>>;
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  is_default: Scalars['Boolean'];
  is_favourite: Scalars['Boolean'];
  long_description?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  public_name: Scalars['String'];
  short_description?: Maybe<Scalars['String']>;
  updated_at: Scalars['packagesDateTime'];
  usps?: Maybe<Scalars['String']>;
};


export type PackagesPackageGroupCollectionGroupsArgs = {
  filter?: InputMaybe<PackagesPackageGroupFilterInput>;
  orderBy?: InputMaybe<Array<PackagesPackageGroupCollectionGroupsOrderByOrderByClause>>;
};

export type PackagesPackageGroupCollectionFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
  usps?: InputMaybe<Scalars['String']>;
};

export type PackagesPackageGroupCollectionGalleryItem = {
  __typename?: 'packagesPackageGroupCollectionGalleryItem';
  alt: Scalars['String'];
  collection_id: Scalars['Int'];
  created_at: Scalars['packagesDateTime'];
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  url?: Maybe<Scalars['String']>;
};

export type PackagesPackageGroupCollectionGalleryItemFilterInput = {
  collection_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

/** A paginated list of PackageGroupCollectionGalleryItem items. */
export type PackagesPackageGroupCollectionGalleryItemPaginator = {
  __typename?: 'packagesPackageGroupCollectionGalleryItemPaginator';
  /** A list of PackageGroupCollectionGalleryItem items. */
  data: Array<PackagesPackageGroupCollectionGalleryItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

/** Allowed column names for PackageGroupCollection.groups.orderBy. */
export enum PackagesPackageGroupCollectionGroupsOrderByColumn {
  Order = 'ORDER'
}

/** Order by clause for PackageGroupCollection.groups.orderBy. */
export type PackagesPackageGroupCollectionGroupsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesPackageGroupCollectionGroupsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** A paginated list of PackageGroupCollection items. */
export type PackagesPackageGroupCollectionPaginator = {
  __typename?: 'packagesPackageGroupCollectionPaginator';
  /** A list of PackageGroupCollection items. */
  data: Array<PackagesPackageGroupCollection>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroupFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  base_price?: InputMaybe<Scalars['Float']>;
  code?: InputMaybe<Scalars['String']>;
  collection_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  final_price?: InputMaybe<Scalars['Float']>;
  internal_name?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  is_free_design?: InputMaybe<Scalars['Boolean']>;
  long_description?: InputMaybe<Scalars['String']>;
  overlay_text?: InputMaybe<Scalars['String']>;
  price_tooltip?: InputMaybe<Scalars['String']>;
  public_name?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
  usps?: InputMaybe<Scalars['String']>;
  wp_slug?: InputMaybe<Scalars['String']>;
};

export type PackagesPackageGroupGalleryItem = {
  __typename?: 'packagesPackageGroupGalleryItem';
  alt: Scalars['String'];
  created_at: Scalars['packagesDateTime'];
  group_id: Scalars['Int'];
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['packagesDateTime'];
  url?: Maybe<Scalars['String']>;
};

export type PackagesPackageGroupGalleryItemFilterInput = {
  created_at?: InputMaybe<Scalars['packagesDate']>;
  group_id?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

/** A paginated list of PackageGroupGalleryItem items. */
export type PackagesPackageGroupGalleryItemPaginator = {
  __typename?: 'packagesPackageGroupGalleryItemPaginator';
  /** A list of PackageGroupGalleryItem items. */
  data: Array<PackagesPackageGroupGalleryItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroupPackage = {
  __typename?: 'packagesPackageGroupPackage';
  group_id: Scalars['Int'];
  id: Scalars['ID'];
  package_id: Scalars['Int'];
};

/** Allowed column names for PackageGroup.packages.orderBy. */
export enum PackagesPackageGroupPackagesOrderByColumn {
  Order = 'ORDER'
}

/** Order by clause for PackageGroup.packages.orderBy. */
export type PackagesPackageGroupPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesPackageGroupPackagesOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** A paginated list of PackageGroup items. */
export type PackagesPackageGroupPaginator = {
  __typename?: 'packagesPackageGroupPaginator';
  /** A list of PackageGroup items. */
  data: Array<PackagesPackageGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageGroupPreset = {
  __typename?: 'packagesPackageGroupPreset';
  code: Scalars['String'];
  id: Scalars['ID'];
  internal_name?: Maybe<Scalars['String']>;
  public_name?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
};

/** A paginated list of Package items. */
export type PackagesPackagePaginator = {
  __typename?: 'packagesPackagePaginator';
  /** A list of Package items. */
  data: Array<PackagesPackage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export type PackagesPackageProduct = {
  __typename?: 'packagesPackageProduct';
  active: Scalars['Boolean'];
  comparison_label?: Maybe<Scalars['String']>;
  created_at: Scalars['packagesDateTime'];
  definition: Scalars['String'];
  id: Scalars['ID'];
  included_options?: Maybe<Scalars['String']>;
  is_included: Scalars['Boolean'];
  is_main: Scalars['Boolean'];
  package_id: Scalars['Int'];
  preset_code?: Maybe<Scalars['String']>;
  pricing?: Maybe<Scalars['String']>;
  root_model?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  type: PackagesPackageProductType;
  updated_at: Scalars['packagesDateTime'];
};

export type PackagesPackageProductFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  comparison_label?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['packagesDate']>;
  package_id?: InputMaybe<Scalars['Int']>;
  preset_code?: InputMaybe<Scalars['String']>;
  root_model?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['packagesDate']>;
};

export type PackagesPackageProductInput = {
  __typename?: 'packagesPackageProductInput';
  active: Scalars['Boolean'];
  definition: Scalars['String'];
  package_id: Scalars['Int'];
  sku: Scalars['String'];
};

/** A paginated list of PackageProduct items. */
export type PackagesPackageProductPaginator = {
  __typename?: 'packagesPackageProductPaginator';
  /** A list of PackageProduct items. */
  data: Array<PackagesPackageProduct>;
  /** Pagination information about the list of items. */
  paginatorInfo: PackagesPaginatorInfo;
};

export enum PackagesPackageProductType {
  Configurable = 'configurable',
  Configuration = 'configuration',
  Simple = 'simple'
}

export enum PackagesPackageType {
  Megadeal = 'megadeal',
  Package = 'package'
}

/** Information about pagination using a Relay style cursor connection. */
export type PackagesPageInfo = {
  __typename?: 'packagesPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PackagesPaginatorInfo = {
  __typename?: 'packagesPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** Allowed column names for Query.packageGalleryItems.orderBy. */
export enum PackagesQueryPackageGalleryItemsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  PackageId = 'PACKAGE_ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.packageGalleryItems.orderBy. */
export type PackagesQueryPackageGalleryItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGalleryItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for Query.packageGroupCollectionGalleryItems.orderBy. */
export enum PackagesQueryPackageGroupCollectionGalleryItemsOrderByColumn {
  CollectionId = 'COLLECTION_ID',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.packageGroupCollectionGalleryItems.orderBy. */
export type PackagesQueryPackageGroupCollectionGalleryItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupCollectionGalleryItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for Query.packageGroupCollections.orderBy. */
export enum PackagesQueryPackageGroupCollectionsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  InternalName = 'INTERNAL_NAME',
  IsDefault = 'IS_DEFAULT',
  IsFavourite = 'IS_FAVOURITE',
  LongDescription = 'LONG_DESCRIPTION',
  Order = 'ORDER',
  PublicName = 'PUBLIC_NAME',
  ShortDescription = 'SHORT_DESCRIPTION',
  UpdatedAt = 'UPDATED_AT',
  Usps = 'USPS'
}

/** Order by clause for Query.packageGroupCollections.orderBy. */
export type PackagesQueryPackageGroupCollectionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupCollectionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for Query.packageGroupGalleryItems.orderBy. */
export enum PackagesQueryPackageGroupGalleryItemsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  GroupId = 'GROUP_ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.packageGroupGalleryItems.orderBy. */
export type PackagesQueryPackageGroupGalleryItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupGalleryItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for Query.packageGroups.orderBy. */
export enum PackagesQueryPackageGroupsOrderByColumn {
  Active = 'ACTIVE',
  BasePrice = 'BASE_PRICE',
  CreatedAt = 'CREATED_AT',
  FinalPrice = 'FINAL_PRICE',
  InternalName = 'INTERNAL_NAME',
  IsDefault = 'IS_DEFAULT',
  IsFavourite = 'IS_FAVOURITE',
  IsFreeDesign = 'IS_FREE_DESIGN',
  LongDescription = 'LONG_DESCRIPTION',
  Order = 'ORDER',
  OverlayText = 'OVERLAY_TEXT',
  PriceTooltip = 'PRICE_TOOLTIP',
  PublicName = 'PUBLIC_NAME',
  ShortDescription = 'SHORT_DESCRIPTION',
  UpdatedAt = 'UPDATED_AT',
  Usps = 'USPS',
  WpSlug = 'WP_SLUG'
}

/** Order by clause for Query.packageGroups.orderBy. */
export type PackagesQueryPackageGroupsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageGroupsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for Query.packageProducts.orderBy. */
export enum PackagesQueryPackageProductsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  PackageId = 'PACKAGE_ID',
  PresetCode = 'PRESET_CODE',
  RootModel = 'ROOT_MODEL',
  Sku = 'SKU',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.packageProducts.orderBy. */
export type PackagesQueryPackageProductsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackageProductsOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

/** Allowed column names for Query.packages.orderBy. */
export enum PackagesQueryPackagesOrderByColumn {
  Active = 'ACTIVE',
  AjatId = 'AJAT_ID',
  BasePrice = 'BASE_PRICE',
  CreatedAt = 'CREATED_AT',
  FinalPrice = 'FINAL_PRICE',
  IsDefault = 'IS_DEFAULT',
  IsFavourite = 'IS_FAVOURITE',
  LongDescription = 'LONG_DESCRIPTION',
  Order = 'ORDER',
  OverlayText = 'OVERLAY_TEXT',
  OverlayTextBottom = 'OVERLAY_TEXT_BOTTOM',
  PriceTooltip = 'PRICE_TOOLTIP',
  PublicName = 'PUBLIC_NAME',
  ShortDescription = 'SHORT_DESCRIPTION',
  UpdatedAt = 'UPDATED_AT',
  WpSlug = 'WP_SLUG'
}

/** Order by clause for Query.packages.orderBy. */
export type PackagesQueryPackagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PackagesQueryPackagesOrderByColumn;
  /** The direction that is used for ordering. */
  order: PackagesSortOrder;
};

export type PackagesSavedPackage = {
  __typename?: 'packagesSavedPackage';
  code: Scalars['String'];
  created_at?: Maybe<Scalars['packagesDateTime']>;
  selection: PackagesSavedPackageSelection;
};

export type PackagesSavedPackageSelection = {
  __typename?: 'packagesSavedPackageSelection';
  allow_package?: Maybe<Scalars['String']>;
  allow_package_group?: Maybe<Scalars['String']>;
  base_price?: Maybe<Scalars['Float']>;
  collection: Scalars['String'];
  configurations: Array<Maybe<PackagesSavedPackageSelectionConfiguration>>;
  final_price: Scalars['Float'];
  group: Scalars['String'];
  package: Scalars['String'];
};

export type PackagesSavedPackageSelectionConfiguration = {
  __typename?: 'packagesSavedPackageSelectionConfiguration';
  configuration_code: Scalars['String'];
  sku: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type PackagesSimplePaginatorInfo = {
  __typename?: 'packagesSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum PackagesSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum PackagesTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export enum PersonalizationAvailableMentionsTypes {
  All = 'all',
  Constant = 'constant',
  School = 'school'
}

/** Allows ordering a list of records. */
export type PersonalizationOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: PersonalizationSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum PersonalizationOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum PersonalizationOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PersonalizationPageInfo = {
  __typename?: 'personalizationPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PersonalizationPaginatorInfo = {
  __typename?: 'personalizationPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** Allowed column names for Query.rules.orderBy. */
export enum PersonalizationQueryRulesOrderByColumn {
  Active = 'ACTIVE',
  Break = 'BREAK',
  CreatedAt = 'CREATED_AT',
  Label = 'LABEL',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.rules.orderBy. */
export type PersonalizationQueryRulesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PersonalizationQueryRulesOrderByColumn;
  /** The direction that is used for ordering. */
  order: PersonalizationSortOrder;
};

export type PersonalizationRule = {
  __typename?: 'personalizationRule';
  actions: Scalars['String'];
  active: Scalars['Boolean'];
  break?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['personalizationDateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  rules: Scalars['String'];
  updated_at: Scalars['personalizationDateTime'];
};

export type PersonalizationRuleFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  break?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['personalizationDate']>;
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['personalizationDate']>;
};

/** A paginated list of Rule items. */
export type PersonalizationRulePaginator = {
  __typename?: 'personalizationRulePaginator';
  /** A list of Rule items. */
  data: Array<PersonalizationRule>;
  /** Pagination information about the list of items. */
  paginatorInfo: PersonalizationPaginatorInfo;
};

export type PersonalizationRulePathUpdateInput = {
  id: Scalars['ID'];
  path: Scalars['String'];
  value: Scalars['String'];
};

export type PersonalizationSelectItem = {
  __typename?: 'personalizationSelectItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type PersonalizationSimplePaginatorInfo = {
  __typename?: 'personalizationSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum PersonalizationSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum PersonalizationTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type SchoolsCountry = {
  __typename?: 'schoolsCountry';
  full_name_english?: Maybe<Scalars['String']>;
  full_name_locale?: Maybe<Scalars['String']>;
  three_letter_abbreviation?: Maybe<Scalars['String']>;
  two_letter_abbreviation?: Maybe<Scalars['String']>;
};

export type SchoolsEducation = {
  __typename?: 'schoolsEducation';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created_at: Scalars['schoolsDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  schools: Array<Maybe<SchoolsSchool>>;
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsEducationFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of Education items. */
export type SchoolsEducationPaginator = {
  __typename?: 'schoolsEducationPaginator';
  /** A list of Education items. */
  data: Array<SchoolsEducation>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsExtensionAttribute = {
  __typename?: 'schoolsExtensionAttribute';
  attribute_backend_type: Scalars['String'];
  attribute_code: Scalars['String'];
  attribute_frontend_type: Scalars['String'];
  attribute_label: Scalars['String'];
  created_at?: Maybe<Scalars['schoolsDateTime']>;
  entity_type: Scalars['String'];
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['schoolsDateTime']>;
};

export type SchoolsExtensionAttributeFilterInput = {
  attribute_code?: InputMaybe<Scalars['String']>;
  attribute_label?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<Scalars['String']>;
};

/** A paginated list of ExtensionAttribute items. */
export type SchoolsExtensionAttributePaginator = {
  __typename?: 'schoolsExtensionAttributePaginator';
  /** A list of ExtensionAttribute items. */
  data: Array<SchoolsExtensionAttribute>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

/** Allows ordering a list of records. */
export type SchoolsOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum SchoolsOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum SchoolsOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type SchoolsPageInfo = {
  __typename?: 'schoolsPageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type SchoolsPaginatorInfo = {
  __typename?: 'schoolsPaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** Allowed column names for Query.educations.orderBy. */
export enum SchoolsQueryEducationsOrderByColumn {
  Active = 'ACTIVE',
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.educations.orderBy. */
export type SchoolsQueryEducationsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQueryEducationsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.extensionAttributes.orderBy. */
export enum SchoolsQueryExtensionAttributesOrderByColumn {
  AttributeCode = 'ATTRIBUTE_CODE',
  AttributeLabel = 'ATTRIBUTE_LABEL',
  CreatedAt = 'CREATED_AT',
  EntityType = 'ENTITY_TYPE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.extensionAttributes.orderBy. */
export type SchoolsQueryExtensionAttributesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQueryExtensionAttributesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolClassByCodes.school. */
export enum SchoolsQuerySchoolClassByCodesSchoolColumn {
  AjatId = 'AJAT_ID'
}

/** Dynamic WHERE conditions for the `school` argument on the query `schoolClassByCodes`. */
export type SchoolsQuerySchoolClassByCodesSchoolWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsQuerySchoolClassByCodesSchoolWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsQuerySchoolClassByCodesSchoolWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsQuerySchoolClassByCodesSchoolWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<SchoolsQuerySchoolClassByCodesSchoolColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `school` argument on the query `schoolClassByCodes`. */
export type SchoolsQuerySchoolClassByCodesSchoolWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsQuerySchoolClassByCodesSchoolWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.schoolClassRepresentatives.orderBy. */
export enum SchoolsQuerySchoolClassRepresentativesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  DiscountUsed = 'DISCOUNT_USED',
  Email = 'EMAIL',
  Name = 'NAME',
  Ssn = 'SSN',
  Telephone = 'TELEPHONE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolClassRepresentatives.orderBy. */
export type SchoolsQuerySchoolClassRepresentativesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolClassRepresentativesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolClassRepresentatives.where. */
export enum SchoolsQuerySchoolClassRepresentativesWhereColumn {
  DiscountUsed = 'DISCOUNT_USED',
  Email = 'EMAIL',
  Name = 'NAME',
  Ssn = 'SSN',
  Telephone = 'TELEPHONE'
}

/** Dynamic WHERE conditions for the `where` argument on the query `schoolClassRepresentatives`. */
export type SchoolsQuerySchoolClassRepresentativesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsQuerySchoolClassRepresentativesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsQuerySchoolClassRepresentativesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsQuerySchoolClassRepresentativesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<SchoolsQuerySchoolClassRepresentativesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `schoolClassRepresentatives`. */
export type SchoolsQuerySchoolClassRepresentativesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsQuerySchoolClassRepresentativesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.schoolClassSchoolTags.orderBy. */
export enum SchoolsQuerySchoolClassSchoolTagsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolClassSchoolTags.orderBy. */
export type SchoolsQuerySchoolClassSchoolTagsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolClassSchoolTagsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolClasses.orderBy. */
export enum SchoolsQuerySchoolClassesOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolClasses.orderBy. */
export type SchoolsQuerySchoolClassesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolClassesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolClasses.where. */
export enum SchoolsQuerySchoolClassesWhereColumn {
  AjatId = 'AJAT_ID',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `schoolClasses`. */
export type SchoolsQuerySchoolClassesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsQuerySchoolClassesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsQuerySchoolClassesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsQuerySchoolClassesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<SchoolsQuerySchoolClassesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `schoolClasses`. */
export type SchoolsQuerySchoolClassesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsQuerySchoolClassesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.schoolEducations.orderBy. */
export enum SchoolsQuerySchoolEducationsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolEducations.orderBy. */
export type SchoolsQuerySchoolEducationsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolEducationsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolPeriods.orderBy. */
export enum SchoolsQuerySchoolPeriodsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  FromDate = 'FROM_DATE',
  GracePaymentDate = 'GRACE_PAYMENT_DATE',
  Name = 'NAME',
  ToDate = 'TO_DATE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolPeriods.orderBy. */
export type SchoolsQuerySchoolPeriodsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolPeriodsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolSalesResponsibles.orderBy. */
export enum SchoolsQuerySchoolSalesResponsiblesOrderByColumn {
  Name = 'NAME'
}

/** Order by clause for Query.schoolSalesResponsibles.orderBy. */
export type SchoolsQuerySchoolSalesResponsiblesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolSalesResponsiblesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolSalesResponsibles.where. */
export enum SchoolsQuerySchoolSalesResponsiblesWhereColumn {
  Active = 'ACTIVE',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `schoolSalesResponsibles`. */
export type SchoolsQuerySchoolSalesResponsiblesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsQuerySchoolSalesResponsiblesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsQuerySchoolSalesResponsiblesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsQuerySchoolSalesResponsiblesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<SchoolsQuerySchoolSalesResponsiblesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `schoolSalesResponsibles`. */
export type SchoolsQuerySchoolSalesResponsiblesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsQuerySchoolSalesResponsiblesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.schoolSalesTeams.orderBy. */
export enum SchoolsQuerySchoolSalesTeamsOrderByColumn {
  Name = 'NAME'
}

/** Order by clause for Query.schoolSalesTeams.orderBy. */
export type SchoolsQuerySchoolSalesTeamsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolSalesTeamsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolSalesTeams.where. */
export enum SchoolsQuerySchoolSalesTeamsWhereColumn {
  Active = 'ACTIVE',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `schoolSalesTeams`. */
export type SchoolsQuerySchoolSalesTeamsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsQuerySchoolSalesTeamsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsQuerySchoolSalesTeamsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsQuerySchoolSalesTeamsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<SchoolsQuerySchoolSalesTeamsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `schoolSalesTeams`. */
export type SchoolsQuerySchoolSalesTeamsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsQuerySchoolSalesTeamsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.schoolSchoolTags.orderBy. */
export enum SchoolsQuerySchoolSchoolTagsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolSchoolTags.orderBy. */
export type SchoolsQuerySchoolSchoolTagsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolSchoolTagsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolTags.orderBy. */
export enum SchoolsQuerySchoolTagsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Tag = 'TAG',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolTags.orderBy. */
export type SchoolsQuerySchoolTagsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolTagsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schoolYears.orderBy. */
export enum SchoolsQuerySchoolYearsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schoolYears.orderBy. */
export type SchoolsQuerySchoolYearsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolYearsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schools.orderBy. */
export enum SchoolsQuerySchoolsOrderByColumn {
  Active = 'ACTIVE',
  AjatId = 'AJAT_ID',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.schools.orderBy. */
export type SchoolsQuerySchoolsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsQuerySchoolsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

/** Allowed column names for Query.schools.where. */
export enum SchoolsQuerySchoolsWhereColumn {
  AjatId = 'AJAT_ID',
  Name = 'NAME'
}

/** Dynamic WHERE conditions for the `where` argument on the query `schools`. */
export type SchoolsQuerySchoolsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsQuerySchoolsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsQuerySchoolsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsQuerySchoolsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<SchoolsQuerySchoolsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `schools`. */
export type SchoolsQuerySchoolsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsQuerySchoolsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** The available SQL operators that are used to filter query results. */
export enum SchoolsSqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

export type SchoolsSchool = {
  __typename?: 'schoolsSchool';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  ajat_id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  classes: Array<SchoolsSchoolClass>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['schoolsDateTime'];
  educations: Array<SchoolsEducation>;
  embroidery_1?: Maybe<Scalars['String']>;
  embroidery_2?: Maybe<Scalars['String']>;
  embroidery_3?: Maybe<Scalars['String']>;
  extension_attributes?: Maybe<Scalars['schoolsJSON']>;
  hero_banner?: Maybe<Scalars['String']>;
  hero_banner_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_note?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logo_2?: Maybe<Scalars['String']>;
  logo_2_url?: Maybe<Scalars['String']>;
  logo_3?: Maybe<Scalars['String']>;
  logo_3_url?: Maybe<Scalars['String']>;
  logo_4?: Maybe<Scalars['String']>;
  logo_4_url?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  marketing_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  periods: Array<SchoolsSchoolPeriod>;
  personalization_logo?: Maybe<Scalars['String']>;
  personalization_logo_url?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  representative_email?: Maybe<Scalars['String']>;
  representative_name?: Maybe<Scalars['String']>;
  representative_ssn?: Maybe<Scalars['String']>;
  representative_telephone?: Maybe<Scalars['String']>;
  representatives: Array<SchoolsSchoolClassRepresentative>;
  sales_responsible?: Maybe<Scalars['String']>;
  sales_team?: Maybe<Scalars['String']>;
  students_amount?: Maybe<Scalars['Int']>;
  tags: Array<SchoolsSchoolTag>;
  updated_at: Scalars['schoolsDateTime'];
};


export type SchoolsSchoolClassesArgs = {
  filter?: InputMaybe<SchoolsSchoolClassFilterInput>;
};


export type SchoolsSchoolEducationsArgs = {
  filter?: InputMaybe<SchoolsSchoolEducationFilterInput>;
};


export type SchoolsSchoolPeriodsArgs = {
  filter?: InputMaybe<SchoolsSchoolPeriodFilterInput>;
  orderBy?: InputMaybe<Array<SchoolsSchoolPeriodsOrderByOrderByClause>>;
};

export type SchoolsSchoolClass = {
  __typename?: 'schoolsSchoolClass';
  active: Scalars['Boolean'];
  ajat_id: Scalars['String'];
  created_at: Scalars['schoolsDateTime'];
  embroidery_1?: Maybe<Scalars['String']>;
  embroidery_2?: Maybe<Scalars['String']>;
  embroidery_3?: Maybe<Scalars['String']>;
  extension_attributes?: Maybe<Scalars['schoolsJSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  representatives: Array<SchoolsSchoolClassRepresentative>;
  school?: Maybe<SchoolsSchool>;
  school_id: Scalars['Int'];
  school_year_id: Scalars['Int'];
  students_amount?: Maybe<Scalars['Int']>;
  tags: Array<SchoolsSchoolTag>;
  updated_at: Scalars['schoolsDateTime'];
  year?: Maybe<SchoolsSchoolYear>;
};

export type SchoolsSchoolClassFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  name?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['Int']>;
  school_year_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolClass items. */
export type SchoolsSchoolClassPaginator = {
  __typename?: 'schoolsSchoolClassPaginator';
  /** A list of SchoolClass items. */
  data: Array<SchoolsSchoolClass>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolClassRepresentative = {
  __typename?: 'schoolsSchoolClassRepresentative';
  classes: Array<Maybe<SchoolsSchoolClass>>;
  created_at: Scalars['schoolsDateTime'];
  discount_amount?: Maybe<Scalars['Float']>;
  discount_percentage?: Maybe<Scalars['Float']>;
  discount_used?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  schools: Array<Maybe<SchoolsSchool>>;
  ssn?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolClassRepresentativeFilterInput = {
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  discount_used?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolClassRepresentative items. */
export type SchoolsSchoolClassRepresentativePaginator = {
  __typename?: 'schoolsSchoolClassRepresentativePaginator';
  /** A list of SchoolClassRepresentative items. */
  data: Array<SchoolsSchoolClassRepresentative>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolClassSchoolClassRepresentative = {
  __typename?: 'schoolsSchoolClassSchoolClassRepresentative';
  id: Scalars['ID'];
  representative_id: Scalars['Int'];
  school_class_id: Scalars['Int'];
};

export type SchoolsSchoolClassSchoolTag = {
  __typename?: 'schoolsSchoolClassSchoolTag';
  created_at: Scalars['schoolsDateTime'];
  school_class_id: Scalars['Int'];
  school_tag_id: Scalars['Int'];
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolClassSchoolTagFilterInput = {
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  school_class_id?: InputMaybe<Scalars['Int']>;
  school_tag_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolClassSchoolTag items. */
export type SchoolsSchoolClassSchoolTagPaginator = {
  __typename?: 'schoolsSchoolClassSchoolTagPaginator';
  /** A list of SchoolClassSchoolTag items. */
  data: Array<SchoolsSchoolClassSchoolTag>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolEducation = {
  __typename?: 'schoolsSchoolEducation';
  created_at: Scalars['schoolsDateTime'];
  education_id: Scalars['Int'];
  school_id: Scalars['Int'];
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolEducationFilterInput = {
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  school_id?: InputMaybe<Scalars['Int']>;
  school_tag_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolEducation items. */
export type SchoolsSchoolEducationPaginator = {
  __typename?: 'schoolsSchoolEducationPaginator';
  /** A list of SchoolEducation items. */
  data: Array<SchoolsSchoolEducation>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  ajat_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  marketing_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of School items. */
export type SchoolsSchoolPaginator = {
  __typename?: 'schoolsSchoolPaginator';
  /** A list of School items. */
  data: Array<SchoolsSchool>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolPeriod = {
  __typename?: 'schoolsSchoolPeriod';
  active: Scalars['Boolean'];
  created_at: Scalars['schoolsDateTime'];
  from_date: Scalars['schoolsDate'];
  grace_payment_date?: Maybe<Scalars['schoolsDate']>;
  id: Scalars['ID'];
  school?: Maybe<SchoolsSchool>;
  to_date: Scalars['schoolsDate'];
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolPeriodFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  from_date?: InputMaybe<Scalars['schoolsDate']>;
  grace_payment_date?: InputMaybe<Scalars['schoolsDate']>;
  name?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['Int']>;
  to_date?: InputMaybe<Scalars['schoolsDate']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolPeriod items. */
export type SchoolsSchoolPeriodPaginator = {
  __typename?: 'schoolsSchoolPeriodPaginator';
  /** A list of SchoolPeriod items. */
  data: Array<SchoolsSchoolPeriod>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

/** Allowed column names for School.periods.orderBy. */
export enum SchoolsSchoolPeriodsOrderByColumn {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  FromDate = 'FROM_DATE',
  GracePaymentDate = 'GRACE_PAYMENT_DATE',
  Name = 'NAME',
  ToDate = 'TO_DATE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for School.periods.orderBy. */
export type SchoolsSchoolPeriodsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SchoolsSchoolPeriodsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SchoolsSortOrder;
};

export type SchoolsSchoolSalesResponsible = {
  __typename?: 'schoolsSchoolSalesResponsible';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SchoolsSchoolSalesResponsibleFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of SchoolSalesResponsible items. */
export type SchoolsSchoolSalesResponsiblePaginator = {
  __typename?: 'schoolsSchoolSalesResponsiblePaginator';
  /** A list of SchoolSalesResponsible items. */
  data: Array<SchoolsSchoolSalesResponsible>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolSalesTeam = {
  __typename?: 'schoolsSchoolSalesTeam';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SchoolsSchoolSalesTeamFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of SchoolSalesTeam items. */
export type SchoolsSchoolSalesTeamPaginator = {
  __typename?: 'schoolsSchoolSalesTeamPaginator';
  /** A list of SchoolSalesTeam items. */
  data: Array<SchoolsSchoolSalesTeam>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolSchoolClassRepresentative = {
  __typename?: 'schoolsSchoolSchoolClassRepresentative';
  id: Scalars['ID'];
  representative_id: Scalars['Int'];
  school_id: Scalars['Int'];
};

export type SchoolsSchoolSchoolTag = {
  __typename?: 'schoolsSchoolSchoolTag';
  created_at: Scalars['schoolsDateTime'];
  school_id: Scalars['Int'];
  school_tag_id: Scalars['Int'];
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolSchoolTagFilterInput = {
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  school_id?: InputMaybe<Scalars['Int']>;
  school_tag_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolSchoolTag items. */
export type SchoolsSchoolSchoolTagPaginator = {
  __typename?: 'schoolsSchoolSchoolTagPaginator';
  /** A list of SchoolSchoolTag items. */
  data: Array<SchoolsSchoolSchoolTag>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolTag = {
  __typename?: 'schoolsSchoolTag';
  created_at: Scalars['schoolsDateTime'];
  id: Scalars['ID'];
  schools: Array<Maybe<SchoolsSchool>>;
  tag: Scalars['String'];
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolTagFilterInput = {
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  tag?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolTag items. */
export type SchoolsSchoolTagPaginator = {
  __typename?: 'schoolsSchoolTagPaginator';
  /** A list of SchoolTag items. */
  data: Array<SchoolsSchoolTag>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

export type SchoolsSchoolYear = {
  __typename?: 'schoolsSchoolYear';
  active: Scalars['Boolean'];
  classes?: Maybe<Array<Maybe<SchoolsSchoolClass>>>;
  created_at: Scalars['schoolsDateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['schoolsDateTime'];
};

export type SchoolsSchoolYearFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['schoolsDate']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['schoolsDate']>;
};

/** A paginated list of SchoolYear items. */
export type SchoolsSchoolYearPaginator = {
  __typename?: 'schoolsSchoolYearPaginator';
  /** A list of SchoolYear items. */
  data: Array<SchoolsSchoolYear>;
  /** Pagination information about the list of items. */
  paginatorInfo: SchoolsPaginatorInfo;
};

/** Information about pagination using a simple paginator. */
export type SchoolsSimplePaginatorInfo = {
  __typename?: 'schoolsSimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SchoolsSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum SchoolsTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** Dynamic WHERE conditions for queries. */
export type SchoolsWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<SchoolsWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<SchoolsWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<SchoolsWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['schoolsMixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type SchoolsWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<SchoolsWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SchoolsSqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};
