import { useState } from 'react'
import { useTranslate } from 'react-admin'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import Fab from '@material-ui/core/Fab'
import PublishIcon from '@mui/icons-material/Publish'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { usePresetExportModelMutation } from 'apollo/configurator/mutations/PresetExportModel.generated'
import { useConfiguratorPresetsLazyQuery } from 'apollo/configurator/queries/ConfiguratorPresets.generated'
import WarningDialog from 'components/modelManager/molecules/popups/WarningDialog'

interface Preset {
    id: number
    internalName: string
    parentModelId: number
    status: boolean
}

interface IUpdateAffectedPresetsButton {
    id: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 25
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        fabProgress: {
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        }
    })
)

const UpdateAffectedPresetsButton = ({ id }: IUpdateAffectedPresetsButton) => {
    const [isActiveWarningPopup, setIsActiveWarningPopup] = useState(false)
    const [isActiveResultsPopup, setIsActiveResultsPopup] = useState(false)

    const translate = useTranslate()
    const classes = useStyles()

    const [dialogKey, setDialogKey] = useState<number>()
    const [presetExportModelMutation] = usePresetExportModelMutation()
    const [list, setList] = useState<Preset[]>()
    const [found, setFound] = useState(true)
    const [exporting, setExporting] = useState(false)

    const [configuratorPresets] = useConfiguratorPresetsLazyQuery({
        variables: {
            first: 99999,
            filter: { parent_model_id: id | 0 }
        }
    })

    const exportChunks = async (presets: Preset[]) => {
        const chunkSize = 5
        const exportedPresets: Preset[] = []

        for (let i = 0; i < presets.length; i += chunkSize) {
            const chunk = presets.slice(i, i + chunkSize)
            const promises = chunk.map((preset) =>
                presetExportModelMutation({
                    variables: {
                        configuratorExportModelId: preset.parentModelId.toString(),
                        preset: preset.id
                    }
                })
                    .then((res) => {
                        exportedPresets.push({
                            ...preset,
                            status: !!res?.data?.configuratorExportModel?.code
                        })
                        setList(exportedPresets)
                        setIsActiveResultsPopup(true)
                        setDialogKey(preset.id)
                    })
                    .catch((error) => {
                        console.error(
                            `Error processing preset ${preset.internalName} (id ${preset.id}):`,
                            error
                        )
                    })
            )
            await Promise.all(promises)
        }
    }

    const exportPresets = async () => {
        configuratorPresets().then(async (res) => {
            const presets = res.data?.configuratorPresets?.data || []
            setFound(!!presets.length)
            setExporting(true)
            await exportChunks(
                presets.map((preset) => ({
                    id: parseInt(preset.id),
                    parentModelId: preset.parent_model_id,
                    internalName: preset.internal_name,
                    status: false
                }))
            )
            setExporting(false)
        })
    }

    const handleFadButtonClick = () => {
        setList([])
        setFound(true)
        setIsActiveWarningPopup(true)
    }

    const handleCloseWarningPopup = () => {
        setIsActiveWarningPopup(false)
    }

    const handleCloseResultsPopup = () => {
        setIsActiveResultsPopup(false)
    }

    const handleContinueWarningPopup = () => {
        exportPresets().then(() => {})
        setIsActiveWarningPopup(false)
        setIsActiveResultsPopup(true)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="update affected presets"
                        color="secondary"
                        onClick={handleFadButtonClick}
                    >
                        <PublishIcon />
                    </Fab>
                </div>
            </div>

            <WarningDialog
                open={isActiveWarningPopup}
                handleClose={handleCloseWarningPopup}
                handleContinue={handleContinueWarningPopup}
                title={translate(
                    'manager.resources.model_manager.update_affected_presets_dialogs.warning_dialog.title'
                )}
                content={translate(
                    'manager.resources.model_manager.update_affected_presets_dialogs.warning_dialog.content'
                )}
            />

            <Dialog
                open={isActiveResultsPopup}
                keepMounted
                onClose={handleCloseResultsPopup}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                key={dialogKey}
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate(
                        'manager.resources.model_manager.update_affected_presets_dialogs.results_dialog.title'
                    )}
                </DialogTitle>
                <DialogContent>
                    {list?.length ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="Affected presets table">
                                <TableBody>
                                    {list.map((item) => (
                                        <TableRow
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item?.status ? '✅' : '❌'}
                                                &nbsp;
                                                {item?.internalName}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : found ? (
                        <CircularProgress />
                    ) : (
                        translate(
                            'manager.resources.model_manager.update_affected_presets_dialogs.results_dialog.content_not_found'
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    {exporting && <CircularProgress />}
                    <Button onClick={handleCloseResultsPopup} variant="contained" color="primary">
                        {translate(
                            'manager.resources.model_manager.update_affected_presets_dialogs.results_dialog.close'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateAffectedPresetsButton
